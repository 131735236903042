.list1 {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
        flex-basis: 33.33%;
        max-width: 33.33%;
        padding: 50px 40px 60px;
        border-top: 3px dashed rgba($gray-dark, 0.08);
        border-right: 3px dashed rgba($gray-dark, 0.08);
        display: flex;

        &:nth-child(1), &:nth-child(2), &:nth-child(3) { 
            border-top: 0px;
        }

        // &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) { 
        //     border-bottom: 0px;
        // }

        &:nth-child(3n) { 
            border-right: 0px;
        }

        .icon {
            display: inline-block;
            min-width: 48px;
            height: 41px;
            margin-top: -3px;
            margin-right: 22px;
            text-align: center;
        }

        p {
            margin-bottom: 0;
        }
    }
}

@media (max-width:991px) {
    .list1 {
        li {
            flex-basis: 50%;
            max-width: 50%;

            &:nth-child(3) { 
                border-top: 3px dashed rgba($gray-dark, 0.08);
            }

            &:nth-child(3n) { 
                border-right: 3px dashed rgba($gray-dark, 0.08);
            }

            &:nth-child(2n) { 
                border-right: 0px;
            }
        }
    }
}

@media (max-width:767px) {
    .list1 {
        li {
            padding: 40px 20px 50px;
        }
    }
}

@media (max-width:479px) {
    .list1 {
        li {
            flex-basis: 100%;
            max-width: 100%;
            padding: 30px 0px 40px;
            border-right: 0px;

            &:nth-child(2) { 
                border-top: 3px dashed rgba($gray-dark, 0.08);
            }

            &:nth-child(3n) { 
                border-right: 0px;
            }
        }
    }
}