.box2 {
    overflow: hidden;
    .project-image {
        height: 740px;

        &.medium {
            height: 610px;
        }

        &.small {
            height: 500px;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    
    .description {
        background: url('../images/section1-description-bg.svg') no-repeat right bottom;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        &.align-center {
            align-items: center;
            p {
                &.lead2 {
                    margin-bottom: 0;
                }
            }
        }

        &.skew-right, &.skew-left {
            background-color: $white;
            z-index: 9;
            &:after {
                content: " ";
                position: absolute;
                display: block;
                width: 120%;
                height: 100%;
                top: 0;
                z-index: -1;
                background: inherit;
                transform-origin: bottom left;
            }
        }
        &.skew-right {
            &:after {
                left: -20%;
                transform: skew(-10deg, 0deg);
            }
        }
        &.skew-left {
            &:after {
                right: -20%;
                transform: skew(10deg, 0deg);
            }
        }

        .inner {
            max-width: 480px;
            padding: 65px;
        }
        h3, h4 {
            margin-bottom: 25px;
        }
    }
}

#our-projects {
    box-shadow: 0 46px 46px -46px rgba(0,0,0,0.2) inset;
    &.box2 {
        .description {
            .inner {
                padding: 0 90px 90px 15px;
            }
        }
    }
}

.projects-list {
    .box2 {
        margin-bottom: 100px;
        box-shadow: 0px 0px 46px 0px rgba(0,0,0,0.2);
    }
}

@media (min-width:1280px) {
    .box2 {
        .description {
            background-size: cover;
        }
    }
}

@media (max-width:991px) {
    .box2 {
        .description {
            .inner {
                padding: 50px;
            }
            h3, h4 {
                margin-bottom: 20px;
            }
        }
    }

    .projects-list {
        .box2 {
            margin-bottom: 40px;
        }
    }

    #our-projects {
        .box2 {
            .display-1 {
                font-size: rem-calc(50);
            }
        }
    }
}

@media (max-width:991px) {
    .box2 {
        .project-image {
            &.small {
                height: 458px;
            }
        }
    }
}

@media (max-width:767px) {
    .box2 {
        .project-image {
            height: 340px;
            &.medium {
                height: 340px;
            }
        }

        .description {
            background-position: center bottom;
            background-size: cover;
            justify-content: center;
            text-align: center;
            min-height: 310px;
            &.skew-right, &.skew-left {
                &:after {
                    transform: skew(0deg, 5deg);
                }
            }

            .inner {
                padding: 70px 30px 30px;
            }
        }
    }

    #our-projects {
        overflow: hidden;
        &.box2 {
            .description {
                .inner {
                    max-width: 100%;
                    padding: 60px 25px 50px;
                }

                h3 {
                    font-size: rem-calc(16);
                }
            }
            .display-1 {
                font-size: rem-calc(40);
            }
        }

        .project-image {
            transform: skewY(3deg);
            margin-bottom: -20px;
        }
    }

    .projects-list {
        .box2 {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

@media (max-width:480px) {
    .box2 {
        .project-image {
            &.small {
                height: 386px;
            }
        }
    }
}