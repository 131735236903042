.about-page {
    .p-b {
        padding-bottom: 270px;
    }

    .circle-group {
        padding-top: 70px;
        margin-bottom: -55px;
        .container {
            max-width: 1280px;
        }
        .rounded-circle {
            margin-top: -270px;
            width: 674px;
            height: 674px;
            max-width: none;
        }
        .circle {
            margin-left: -155px;
        }
    }

    .box2 {
        position: relative;
        z-index: 9;
        .description {
            box-shadow: inset  0  46px 46px -46px rgba(0,0,0,0.2), inset  0 -46px 46px -46px rgba(0,0,0,0.2);
            &:after {
                box-shadow: inset  0  46px 46px -46px rgba(0,0,0,0.2), inset  0 -46px 46px -46px rgba(0,0,0,0.2);
            }
        }
    }
}

@media (max-width:1279px) {
    .about-page {
        .p-b {
            padding-bottom: 80px;
        }

        .circle-group {
            padding-top: 25px;
            margin-bottom: -110px;
            .rounded-circle {
                margin-top: -80px;
                width: 423px;
                height: 423px;
                max-width: none;
            }
        }
    }
}

@media (max-width:991px) {
    .about-page {
        .circle-group {
            .rounded-circle {
                margin-left: -80px;
            }
            .circle {
                margin-left: -225px;
                .text-wrap {
                    max-width: 330px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .about-page {
        .p-b {
            padding-bottom: 0px;
        }

        .circle-group {
            padding-top: 10px;
            margin-bottom: -80px;

            .rounded-circle {
                margin-top: 0px;
                margin-left: -100px;
                width: 380px;
                height: 380px;
                max-width: none;
            }

            .circle {
                margin-top: -145px;
                margin-left: -70px;
                padding-left: 60px;
            }

        }

        .box2 {
            box-shadow: 0  46px 46px -46px rgba(0,0,0,0.2);
            .description {
                box-shadow: none;                
                &:after {
                    box-shadow: none;
                }
            }
        }
    }
}

@media (max-width:480px) {
    .about-page {
        .circle-group {
            .circle {
                .text-wrap {
                    max-width: 270px;
                }
            }
        }
    }
}