/*
* main-navigation
*/
#main-navigation {
    padding-top: 75px;
    position: relative;
    z-index: 9;

    &.has-circle:before {
        display: none;
    }

    h2 {
        display: none;
    }

    .nav-item {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .nav-link {
        padding: 0;
        &.active {
            font-weight: 700;
        }
    }
}
@media (max-width:1199px) {
    #main-navigation {
        padding-top: 30px;
    }
}
@media (max-width:991px) {
    #main-navigation {
        .nav-item {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
@media (max-width:767px) {
    #main-navigation {
        position: fixed;
        right: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 30px 20px 10px;
        background: $white;
        &.show-nav {
            right: 0;
        }

        &:after {
            content: '';
            display: block;
            width: 83px;
            height: 119px;
            position: absolute;
            right: 0;
            top: 125px;
            background: url('../images/arc.svg');
        }

        &.has-circle:before {
            display: block;
        }    

        h2 {
            display: block;
            margin-bottom: 60px;
        }

        .nav {
            display: block;
        }

        .nav-item {
            margin-bottom: 60px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media (max-height:415px) and (orientation: landscape) {
    #main-navigation {
        h2 {
            margin-bottom: 20px;
        }

        .nav-item {
            margin-bottom: 15px;
        }
    }
}