#our-services {
    padding: 100px 0px 130px;
    h2 {
        margin-bottom: 70px;
    }
    
    li {
        img {
            margin-bottom: 30px;
            width: auto;
        }
    }

    .owl-stage-outer {
        margin-bottom: 30px;
    }
}

@media (max-width:767px) {
    #our-services {
        text-align: center;
        padding-bottom: 85px;

        h2 {
            margin-bottom: 35px;
        }

        li {
            img {
                margin: 0 auto 30px;
            }
        }

        .read-more-link {
            display: none;
        }
    }
}
