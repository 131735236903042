.slideshow {
    overflow: hidden;
    .slide {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        .bg-image {
            width: 100%;
            visibility: hidden;
        }
    }    
}

.slideshow1 {
    .slide {
        min-height: 430px;
    }
    .cycle-prev, .cycle-next {
        display: block;
        width: 103px;
        height: 103px;
        background: rgba($primary, 0.8);
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;
        @include vertical-center;
        z-index: 1000;
        cursor: pointer;
    }

    .cycle-prev {
        left: 60px;
        background-image: url('../images/previous-arrow.svg');
    }

    .cycle-next {
        right: 60px;
        background-image: url('../images/next-arrow.svg');
    }
}

.slideshow2 {
    margin-bottom: 50px;
    .slideshow {
        margin-bottom: 40px;
    }
    .slide {
        min-height: 610px;
        max-height: 610px;
        position: relative;
        &:after {
            content: " ";
            position: absolute;
            display: block;
            width: 15%;
            height: 100%;
            top: 0;
            left: 85%;
            z-index: -1;
            background: $white;
            transform-origin: bottom left;
            transform: skew(-10deg, 0deg);
        }
    }
    .cycle-prev, .cycle-next {
        display: inline-block;
        width: 43px;
        height: 43px;
        background: rgba($primary, 0.8);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 11px 18px;
        border-radius: 50%;
        // @include vertical-center;
        // z-index: 1000;
        cursor: pointer;
    }

    .cycle-prev {
        background-image: url('../images/previous-arrow.svg');
    }

    .cycle-next {
        margin-left: 20px;
        background-image: url('../images/next-arrow.svg');
    }
}

@media (max-width:991px) {
    .slideshow1 {
        .cycle-prev, .cycle-next {
            width: 68px;
            height: 68px;
            background-size: 17px 27px;
        }
        .cycle-prev {
            left: 40px;
        }
        .cycle-next {
            right: 40px;
        }
    }
}

@media (max-width:767px) {
    .slideshow1 {
        .slide {
            min-height: 280px;
        }
        .cycle-prev, .cycle-next {
            width: 34px;
            height: 34px;
            background-size: 9px 14px;
            top: auto;
            bottom: 20px;
        }
        .cycle-prev {
            left: 50%;
            transform: translateX(-44px);
        }
        .cycle-next {
            right: 50%;
            transform: translateX(44px);
        }
    }

    .slideshow2 {
        margin-bottom: 30px;
        .slideshow {
            margin-bottom: 30px;
        }
        .slide {
            min-height: 560px;
            max-height: 560px;
            &:after {
                width: 100%;
                height: 8%;
                left: 0;
                top: 0;
                transform-origin: top left;
                transform: skew(0deg, -3deg);
            }
        }

        .cycle-prev, .cycle-next {
            width: 34px;
            height: 34px;
            background-size: 8px 14px;
            position: relative;
        }

        .cycle-prev {
            left: 50%;
            transform: translateX(-44px);
        }
        .cycle-next {
            left: 50%;
            transform: translateX(-44px);
        }
    }
}