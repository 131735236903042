@charset "UTF-8";
/*  ==========================================================================
	Setup: Variables, Mixins
    ========================================================================== */
/*  ==========================================================================
	Fonts
    ========================================================================== */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-thin.eot");
  src: url("../fonts/roboto-thin.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-thin.woff") format("woff"), url("../fonts/roboto-thin.ttf") format("truetype"), url("../fonts/roboto-thin.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-thin.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-thin.eot");
  src: url("../fonts/roboto-thin.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-thin.woff") format("woff"), url("../fonts/roboto-thin.ttf") format("truetype"), url("../fonts/roboto-thin.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-thin.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-light.eot");
  src: url("../fonts/roboto-light.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-light.woff") format("woff"), url("../fonts/roboto-light.ttf") format("truetype"), url("../fonts/roboto-light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-light.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-lightitalic.eot");
  src: url("../fonts/roboto-lightitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-lightitalic.woff") format("woff"), url("../fonts/roboto-lightitalic.ttf") format("truetype"), url("../fonts/roboto-lightitalic.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-lightitalic.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-regular.eot");
  src: url("../fonts/roboto-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-regular.woff") format("woff"), url("../fonts/roboto-regular.ttf") format("truetype"), url("../fonts/roboto-regular.svg#Roboto") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-regular.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-italic.eot");
  src: url("../fonts/roboto-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-italic.woff") format("woff"), url("../fonts/roboto-italic.ttf") format("truetype"), url("../fonts/roboto-italic.svg#Roboto") format("svg");
  font-weight: normal;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-italic.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-medium.eot");
  src: url("../fonts/roboto-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-medium.woff") format("woff"), url("../fonts/roboto-medium.ttf") format("truetype"), url("../fonts/roboto-medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-medium.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-mediumitalic.eot");
  src: url("../fonts/roboto-mediumitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-mediumitalic.woff") format("woff"), url("../fonts/roboto-mediumitalic.ttf") format("truetype"), url("../fonts/roboto-mediumitalic.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-mediumitalic.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-bold.eot");
  src: url("../fonts/roboto-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-bold.woff") format("woff"), url("../fonts/roboto-bold.ttf") format("truetype"), url("../fonts/roboto-bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-bold.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-bolditalic.eot");
  src: url("../fonts/roboto-bolditalic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-bolditalic.woff") format("woff"), url("../fonts/roboto-bolditalic.ttf") format("truetype"), url("../fonts/roboto-bolditalic.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-bolditalic.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-black.eot");
  src: url("../fonts/roboto-black.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-black.woff") format("woff"), url("../fonts/roboto-black.ttf") format("truetype"), url("../fonts/roboto-black.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-black.svg#Roboto") format("svg"); } }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-black.eot");
  src: url("../fonts/roboto-black.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-black.woff") format("woff"), url("../fonts/roboto-black.ttf") format("truetype"), url("../fonts/roboto-black.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: italic; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-black.svg#Roboto") format("svg"); } }

/*  ==========================================================================
	Global Settings & Utilities
    ========================================================================== */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body, #container {
  overflow-x: hidden; }

.group::after {
  display: block;
  clear: both;
  content: ""; }

img {
  max-width: 100%; }

figure {
  margin: 0px; }

a:hover {
  text-decoration: none; }

h2, h3 {
  text-transform: uppercase; }

h2 {
  margin-bottom: 45px; }

p {
  margin-bottom: 30px; }
  p.lead {
    line-height: calc(44 / 35); }

@media (max-width: 991px) {
  h1 {
    font-size: 2.5rem; } }

/* read-more-link */
.read-more-link {
  text-transform: uppercase;
  font-weight: 700; }
  .read-more-link:after {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    border-top: 2px solid #253339;
    border-left: 2px solid #253339;
    transform: rotate(135deg);
    margin-left: 16px; }

.section {
  padding: 130px 0px; }

@media (max-width: 991px) {
  .section {
    padding: 90px 0px; } }

@media (max-width: 767px) {
  .section {
    padding: 60px 0px; } }

.text-wrap {
  position: relative;
  z-index: 2; }

.display-1 {
  line-height: calc(60 / 55); }

.lead2 {
  font-size: 2.1875rem;
  line-height: calc(50 / 35); }

@media (max-width: 991px) {
  .display-1 {
    font-size: 2.5rem; } }

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.5rem; }
  .lead2 {
    font-size: 1.5625rem; } }

/*  ==========================================================================
	Layouts
    ========================================================================== */
#content {
  padding-top: 70px;
  padding-bottom: 90px;
  background: #fff; }

@media (max-width: 767px) {
  #content {
    padding-top: 30px;
    padding-bottom: 60px; } }

/*  ==========================================================================
	Vendor
    ========================================================================== */
/**
 * Owl Carousel v2.3.3
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
   *  Owl Carousel - Animate Plugin
   */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
   * 	Owl Carousel - Auto Height Plugin
   */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
   * 	Owl Carousel - Lazy Load Plugin
   */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
   * 	Owl Carousel - Video Plugin
   */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*  ==========================================================================
	Components
    ========================================================================== */
#header {
  padding-bottom: 40px;
  position: relative;
  /*
    &:before {
        content: '';
        display: block;
        width: 120%;
        height: 120px;
        background: inherit;
        position: absolute;
        z-index: 1;
        top: 77%;
        // top: 190px;
        left: -10%;
        // transform-origin: top right;
        transform-origin: center center;
        transform: skewY(-8deg);
        // transform: rotate(-9deg);
        box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2);
    }
    */ }
  #header:before {
    content: '';
    display: block;
    width: 120%;
    height: 120px;
    background: inherit;
    position: absolute;
    z-index: 1;
    top: 199px;
    left: -10%;
    transform-origin: center center;
    transform: skewY(-8deg);
    box-shadow: 0 -46px 46px -46px rgba(0, 0, 0, 0.2); }
  #header .logo {
    padding-top: 30px;
    position: relative; }
    #header .logo:before {
      width: 438px;
      height: 286px;
      content: '';
      display: block;
      background: url("../images/logo-bg.svg") no-repeat left center;
      position: absolute;
      top: -10px;
      left: -75px;
      z-index: -1; }
    #header .logo a {
      display: block;
      width: 100%;
      max-width: 332px;
      height: 210px;
      background: url("../images/logo.svg") no-repeat right top;
      position: relative;
      left: -48px; }

@media (max-width: 1199px) {
  #header {
    padding-bottom: 30px; }
    #header:before {
      top: 112px;
      transform: skewY(-6deg); }
    #header .logo {
      padding-top: 25px; }
      #header .logo:before {
        width: 255px;
        height: 178px;
        left: -12px;
        background-size: contain; }
      #header .logo a {
        max-width: 172px;
        height: 106px;
        left: 22px;
        background-size: contain; } }

@media (max-width: 991px) {
  #header:before {
    top: 122px; } }

@media (max-width: 767px) {
  #header {
    padding-bottom: 20px;
    margin-bottom: 30px; }
    #header:before {
      top: 114px; }
    #header .logo {
      padding-top: 12px; }
      #header .logo:before {
        width: 210px;
        height: 135px;
        left: -20px;
        top: 0; }
      #header .logo a {
        max-width: 150px;
        height: 96px;
        left: 0; } }

/*
* main-navigation
*/
#main-navigation {
  padding-top: 75px;
  position: relative;
  z-index: 9; }
  #main-navigation.has-circle:before {
    display: none; }
  #main-navigation h2 {
    display: none; }
  #main-navigation .nav-item {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase; }
  #main-navigation .nav-link {
    padding: 0; }
    #main-navigation .nav-link.active {
      font-weight: 700; }

@media (max-width: 1199px) {
  #main-navigation {
    padding-top: 30px; } }

@media (max-width: 991px) {
  #main-navigation .nav-item {
    padding-left: 10px;
    padding-right: 10px; } }

@media (max-width: 767px) {
  #main-navigation {
    position: fixed;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 30px 20px 10px;
    background: #fff; }
    #main-navigation.show-nav {
      right: 0; }
    #main-navigation:after {
      content: '';
      display: block;
      width: 83px;
      height: 119px;
      position: absolute;
      right: 0;
      top: 125px;
      background: url("../images/arc.svg"); }
    #main-navigation.has-circle:before {
      display: block; }
    #main-navigation h2 {
      display: block;
      margin-bottom: 60px; }
    #main-navigation .nav {
      display: block; }
    #main-navigation .nav-item {
      margin-bottom: 60px;
      padding-left: 20px;
      padding-right: 20px; } }

@media (max-height: 415px) and (orientation: landscape) {
  #main-navigation h2 {
    margin-bottom: 20px; }
  #main-navigation .nav-item {
    margin-bottom: 15px; } }

.menu-btn {
  position: absolute;
  right: 15px;
  z-index: 99;
  padding: 0px;
  background: transparent;
  padding: 0px;
  height: 21px;
  width: 25px;
  border: 0px;
  margin-top: 34px;
  cursor: pointer;
  display: none; }
  .menu-btn span.icon-bar {
    background: #292935;
    display: block;
    cursor: pointer;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    position: relative;
    transform: rotate(0deg); }
    .menu-btn span.icon-bar:first-child {
      margin-top: 0px; }
    .menu-btn span.icon-bar:first-child, .menu-btn span.icon-bar:last-child {
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s; }
  .menu-btn:focus {
    outline: none; }
  .menu-btn.active span.icon-bar:first-child + span {
    background-color: transparent;
    opacity: 0; }
  .menu-btn.active span.icon-bar:first-child {
    transform: rotate(45deg);
    left: 0px;
    top: 7px; }
  .menu-btn.active span.icon-bar:last-child {
    transform: rotate(-45deg);
    left: 0px;
    top: -9px; }

@media screen and (max-width: 767px) {
  .menu-btn {
    display: block; } }

/* footer */
#footer {
  background: #253339;
  background-blend-mode: multiply;
  padding: 20px 0px 106px;
  color: #fff;
  position: relative; }
  #footer:before {
    content: '';
    display: block;
    background: rgba(37, 51, 57, 0.5);
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
    height: 100%;
    z-index: 9;
    transform: skewY(-3deg); }
  #footer:after {
    background: inherit;
    top: -50px;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg); }
  #footer .container {
    position: relative;
    z-index: 99; }
  #footer .logo2 {
    padding-top: 25px;
    margin-bottom: 10px;
    position: relative; }
    #footer .logo2:before {
      content: '';
      display: block;
      background: url("../images/logo2-bg.png") no-repeat center center;
      position: absolute;
      top: -53px;
      left: -121px;
      width: 294px;
      height: 274px; }
    #footer .logo2 a {
      display: block;
      width: 146px;
      height: 93px;
      background: url("../images/logo2.png") no-repeat right top; }
  #footer .copyright {
    font-size: 0.8125rem; }
  #footer .contact-details ul {
    padding-left: 0;
    list-style: none; }
  #footer .contact-details li {
    font-size: 1rem;
    line-height: calc(31 / 16); }
    #footer .contact-details li img {
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
      margin-right: 15px; }
    #footer .contact-details li a {
      color: inherit; }
    #footer .contact-details li .inner {
      display: inline-block; }
  #footer .contact-details address {
    margin-bottom: 0; }
    #footer .contact-details address p {
      margin-bottom: 0; }
  #footer .note {
    color: rgba(255, 255, 255, 0.53);
    margin-left: 32px; }

@media (max-width: 767px) {
  #footer {
    text-align: center; }
    #footer .logo2 {
      width: 146px;
      margin: 0px auto 10px; }
    #footer .contact-details {
      width: 230px;
      margin: 0px auto;
      text-align: left; }
    #footer .note {
      margin-left: 0px; } }

.circle {
  background: #FFD241;
  display: block;
  border-radius: 50%;
  width: 750px;
  height: 750px;
  display: flex;
  align-items: center; }
  .circle .text-wrap {
    position: relative;
    max-width: 390px;
    margin: 0px auto; }
    .circle .text-wrap p {
      font-size: 1.25rem; }

@media (max-width: 767px) {
  .circle {
    width: 700px;
    height: 700px; }
    .circle .text-wrap {
      margin: 0px 15px; } }

@media (max-width: 480px) {
  .circle .text-wrap {
    max-width: 270px; } }

.has-circle {
  position: relative; }
  .has-circle:before {
    display: block;
    content: '';
    background: #FFD241;
    border-radius: 50%;
    width: 750px;
    height: 750px;
    position: absolute;
    background-blend-mode: multiply; }
  .has-circle.accent-circle:before {
    background-color: #007934;
    width: 173px;
    height: 173px; }

.circle1:before {
  top: -435px;
  right: -450px; }

.circle2:before {
  top: -10px;
  right: -342px; }

.circle3:before {
  top: -82px;
  right: 0;
  transform: translateX(110%); }

.services2 .circle3:before {
  right: auto;
  top: -246px;
  left: 0px;
  transform: translateX(-88%);
  z-index: 9; }

.circle4:before {
  top: 0px;
  left: 0;
  transform: translateX(-70%);
  z-index: -1; }

.circle5:before {
  top: 0px;
  left: 0;
  transform: translateX(-70%) translateY(27%); }

.single-project .circle5:before {
  transform: translateX(-70%) translateY(-18%); }

.circle6:before {
  width: 918px;
  height: 918px;
  bottom: 0px;
  left: 0;
  transform: translateX(-36%) translateY(0);
  z-index: -1; }

.has-arc {
  position: relative; }
  .has-arc:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 500px solid transparent;
    border-right: 500px solid transparent;
    border-top: 670px solid #FFD241;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    background-blend-mode: multiply; }

.arc1:after {
  left: -65%;
  top: -290px;
  border-left-width: 600px;
  border-right-width: 600px;
  border-top-width: 900px; }

.arc2:after {
  left: -263px;
  bottom: -366px; }

.arc3:after {
  transform: translateX(-254px) translateY(-160px);
  left: 0;
  top: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 180px solid #007934;
  border-top: 0; }

.slideshow2.arc3:after {
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  transform: translateX(125px) translateY(15px);
  z-index: 100; }

.arc4:after {
  transform: translateX(874px) translateY(-540px);
  right: 0;
  top: 0;
  border-left: 800px solid transparent;
  border-right: 800px solid transparent;
  border-bottom: 950px solid #FFD241;
  border-top: 0; }

.arc5:after {
  transform: translateX(160px) translateY(-330px);
  right: 0;
  top: 0;
  border-left: 280px solid transparent;
  border-right: 280px solid transparent;
  border-bottom: 310px solid #FFD241;
  border-top: 0; }

.arc6:after {
  right: -55%;
  top: 57%;
  border-left: 590px solid transparent;
  border-right: 590px solid transparent;
  border-top: 730px solid #FFD241;
  z-index: -1; }

@media (max-width: 1199px) {
  .circle3:before {
    transform: translateX(50%); }
  .services2 .circle3:before {
    transform: translateX(-45%); }
  .arc2:after {
    left: -33%; }
  .arc3:after {
    border-left-width: 114px;
    border-right-width: 114px;
    border-bottom-width: 144px;
    transform: translateX(-170px) translateY(-180px); }
  .arc5:after {
    transform: translateX(160px) translateY(-220px); } }

@media (max-width: 991px) {
  .has-circle:before {
    width: 590px;
    height: 590px; }
  .circle1:before {
    right: 0;
    transform: translateX(40%); }
  .circle6:before {
    transform: translateX(-26%) translateY(20%); }
  .arc1:after {
    left: -87%;
    top: -190px; }
  .arc4:after {
    transform: translateX(824px) translateY(-415px); }
  .arc6:after {
    right: -82%; } }

@media (max-width: 767px) {
  .has-circle:before {
    width: 500px;
    height: 500px; }
  .circle1:before {
    top: -270px;
    transform: translateX(80%); }
  .circle3:before {
    top: -116px; }
  .services2 .circle3:before {
    right: 0px;
    top: -146px;
    left: auto;
    transform: translateX(78px); }
  .circle5:before {
    transform: translateX(-60%) translateY(7%); }
  .circle6:before {
    transform: translateX(-46%) translateY(20%); }
  .arc1:after {
    left: auto;
    right: -50%;
    top: -140px; }
  .arc2:after {
    left: -160%;
    bottom: 10%; }
  .arc3:after {
    border-left-width: 50px;
    border-right-width: 50px;
    border-bottom-width: 60px;
    transform: translateX(-50px) translateY(-82px); }
  .slideshow2.arc3:after {
    transform: translateX(55px) translateY(15px); }
  .arc4:after {
    transform: translateX(60%) translateY(15%); }
  .arc5:after {
    transform: translateX(56%) translateY(-80%); }
  .arc6:after {
    border-left-width: 380px;
    border-right-width: 380px;
    border-bottom-width: 470px;
    right: -150%; } }

.btn {
  text-transform: uppercase;
  min-width: 152px; }

/* box1 */
.box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  padding-left: 125px;
  padding-right: 15px;
  position: relative; }
  .box1 blockquote p.lead:before {
    content: '“';
    display: block;
    position: absolute;
    left: -60px;
    top: -50px;
    font-size: 6.25rem; }

@media (max-width: 767px) {
  .box1 blockquote p.lead:before {
    left: -40px; } }

.box2 {
  overflow: hidden; }
  .box2 .project-image {
    height: 740px; }
    .box2 .project-image.medium {
      height: 610px; }
    .box2 .project-image.small {
      height: 500px; }
    .box2 .project-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .box2 .description {
    background: url("../images/section1-description-bg.svg") no-repeat right bottom;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
    .box2 .description.align-center {
      align-items: center; }
      .box2 .description.align-center p.lead2 {
        margin-bottom: 0; }
    .box2 .description.skew-right, .box2 .description.skew-left {
      background-color: #fff;
      z-index: 9; }
      .box2 .description.skew-right:after, .box2 .description.skew-left:after {
        content: " ";
        position: absolute;
        display: block;
        width: 120%;
        height: 100%;
        top: 0;
        z-index: -1;
        background: inherit;
        transform-origin: bottom left; }
    .box2 .description.skew-right:after {
      left: -20%;
      transform: skew(-10deg, 0deg); }
    .box2 .description.skew-left:after {
      right: -20%;
      transform: skew(10deg, 0deg); }
    .box2 .description .inner {
      max-width: 480px;
      padding: 65px; }
    .box2 .description h3, .box2 .description h4 {
      margin-bottom: 25px; }

#our-projects {
  box-shadow: 0 46px 46px -46px rgba(0, 0, 0, 0.2) inset; }
  #our-projects.box2 .description .inner {
    padding: 0 90px 90px 15px; }

.projects-list .box2 {
  margin-bottom: 100px;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.2); }

@media (min-width: 1280px) {
  .box2 .description {
    background-size: cover; } }

@media (max-width: 991px) {
  .box2 .description .inner {
    padding: 50px; }
  .box2 .description h3, .box2 .description h4 {
    margin-bottom: 20px; }
  .projects-list .box2 {
    margin-bottom: 40px; }
  #our-projects .box2 .display-1 {
    font-size: 3.125rem; } }

@media (max-width: 991px) {
  .box2 .project-image.small {
    height: 458px; } }

@media (max-width: 767px) {
  .box2 .project-image {
    height: 340px; }
    .box2 .project-image.medium {
      height: 340px; }
  .box2 .description {
    background-position: center bottom;
    background-size: cover;
    justify-content: center;
    text-align: center;
    min-height: 310px; }
    .box2 .description.skew-right:after, .box2 .description.skew-left:after {
      transform: skew(0deg, 5deg); }
    .box2 .description .inner {
      padding: 70px 30px 30px; }
  #our-projects {
    overflow: hidden; }
    #our-projects.box2 .description .inner {
      max-width: 100%;
      padding: 60px 25px 50px; }
    #our-projects.box2 .description h3 {
      font-size: 1rem; }
    #our-projects.box2 .display-1 {
      font-size: 2.5rem; }
    #our-projects .project-image {
      transform: skewY(3deg);
      margin-bottom: -20px; }
  .projects-list .box2 {
    margin-left: -15px;
    margin-right: -15px; } }

@media (max-width: 480px) {
  .box2 .project-image.small {
    height: 386px; } }

.list1 {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .list1 li {
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: 50px 40px 60px;
    border-top: 3px dashed rgba(37, 51, 57, 0.08);
    border-right: 3px dashed rgba(37, 51, 57, 0.08);
    display: flex; }
    .list1 li:nth-child(1), .list1 li:nth-child(2), .list1 li:nth-child(3) {
      border-top: 0px; }
    .list1 li:nth-child(3n) {
      border-right: 0px; }
    .list1 li .icon {
      display: inline-block;
      min-width: 48px;
      height: 41px;
      margin-top: -3px;
      margin-right: 22px;
      text-align: center; }
    .list1 li p {
      margin-bottom: 0; }

@media (max-width: 991px) {
  .list1 li {
    flex-basis: 50%;
    max-width: 50%; }
    .list1 li:nth-child(3) {
      border-top: 3px dashed rgba(37, 51, 57, 0.08); }
    .list1 li:nth-child(3n) {
      border-right: 3px dashed rgba(37, 51, 57, 0.08); }
    .list1 li:nth-child(2n) {
      border-right: 0px; } }

@media (max-width: 767px) {
  .list1 li {
    padding: 40px 20px 50px; } }

@media (max-width: 479px) {
  .list1 li {
    flex-basis: 100%;
    max-width: 100%;
    padding: 30px 0px 40px;
    border-right: 0px; }
    .list1 li:nth-child(2) {
      border-top: 3px dashed rgba(37, 51, 57, 0.08); }
    .list1 li:nth-child(3n) {
      border-right: 0px; } }

.tabs .active-item {
  display: none;
  position: relative;
  border-bottom: 2px solid #253339;
  padding-bottom: 8px; }
  .tabs .active-item:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-left: 3px solid #253339;
    border-bottom: 3px solid #253339;
    transform: rotate(-45deg);
    position: absolute;
    right: 0;
    top: 0; }

.tabs .nav {
  margin-bottom: 90px;
  justify-content: flex-end; }

.tabs .nav-item {
  margin-left: 60px;
  font-size: 1.25rem; }
  .tabs .nav-item:first-child {
    margin-left: 0px; }

.tabs .nav-link {
  padding: 0px 10px 30px;
  position: relative; }
  .tabs .nav-link:after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 9px solid #FFD241;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: scale(0);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s; }
  .tabs .nav-link.active {
    font-weight: 700; }
  .tabs .nav-link.active:after, .tabs .nav-link:hover:after {
    transform: scale(1); }

@media (max-width: 991px) {
  .tabs .nav {
    margin-bottom: 60px; }
  .tabs .nav-item {
    margin-left: 40px; } }

@media (min-width: 768px) {
  .tabs .nav {
    display: flex !important; } }

@media (max-width: 767px) {
  .tabs .active-item {
    display: block;
    margin-bottom: 40px; }
  .tabs .nav {
    background: #fff;
    border-left: 1px solid #253339;
    border-right: 1px solid #253339;
    width: 100%;
    z-index: 99;
    display: none;
    position: absolute;
    left: 0;
    top: 34px; }
  .tabs .nav-item {
    margin-left: 0px;
    font-size: 1rem; }
  .tabs .nav-link {
    padding: 10px 10px; }
    .tabs .nav-link:after {
      border-bottom: 1px solid #253339;
      transform: scale(1); } }

.post .display-2 {
  text-transform: uppercase;
  margin-bottom: 55px; }

.post p strong {
  font-weight: 600; }

.post blockquote cite {
  font-style: normal; }

.post blockquote p.lead {
  margin-bottom: 35px; }

.post blockquote p.metadata .divider {
  margin-left: 1rem;
  margin-right: 1rem; }

@media (max-width: 991px) {
  .post .display-2 {
    margin-bottom: 40px; } }

.form .form-group {
  margin-bottom: 50px; }

.form .form-control {
  font-size: 0.875rem;
  background: transparent;
  border-width: 0px 0px 2px 0px;
  border-radius: 0px;
  padding-left: 0;
  text-transform: uppercase; }
  .form .form-control:focus {
    outline: none;
    box-shadow: none; }

.form .code {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .form .code .code-image {
    width: 185px;
    max-height: 38px; }
  .form .code .form-control {
    display: inline-block;
    width: calc(100% - 205px); }

@media (max-width: 767px) {
  .form .form-group {
    margin-bottom: 0px; }
  .form .form-control, .form .code-image {
    margin-bottom: 50px; }
  .form .btn[type="submit"] {
    width: 100%; } }

#introduction {
  padding-bottom: 100px;
  position: relative; }
  #introduction .row {
    position: relative;
    z-index: 1; }
  #introduction p.lead {
    font-size: 3.125rem;
    line-height: calc(70 / 50); }

@media (max-width: 1199px) {
  #introduction:before {
    top: -145px; } }

@media (max-width: 991px) {
  #introduction:before {
    top: -135px; }
  #introduction p.lead {
    font-size: 2.1875rem; } }

@media (max-width: 767px) {
  #introduction {
    padding-bottom: 40px; }
    #introduction:before {
      top: -110px; }
    #introduction p.lead {
      font-size: 1.625rem; } }

.owl-carousel {
  overflow: hidden;
  list-style: none;
  padding-left: 0; }
  .owl-carousel .owl-stage {
    display: flex;
    align-items: flex-start; }
  .owl-carousel .owl-dots .owl-dot {
    display: inline-block;
    width: 11px;
    height: 11px;
    border: 2px solid #253339;
    background: #253339;
    border-radius: 50%;
    margin-right: 15px; }
    .owl-carousel .owl-dots .owl-dot.active {
      background: #fff; }
    .owl-carousel .owl-dots .owl-dot:focus {
      outline: none; }

@media (max-width: 480px) {
  .owl-carousel .owl-dots .owl-dot {
    margin-right: 10px; } }

.slideshow {
  overflow: hidden; }
  .slideshow .slide {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
    .slideshow .slide .bg-image {
      width: 100%;
      visibility: hidden; }

.slideshow1 .slide {
  min-height: 430px; }

.slideshow1 .cycle-prev, .slideshow1 .cycle-next {
  display: block;
  width: 103px;
  height: 103px;
  background: rgba(37, 51, 57, 0.8);
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  cursor: pointer; }

.slideshow1 .cycle-prev {
  left: 60px;
  background-image: url("../images/previous-arrow.svg"); }

.slideshow1 .cycle-next {
  right: 60px;
  background-image: url("../images/next-arrow.svg"); }

.slideshow2 {
  margin-bottom: 50px; }
  .slideshow2 .slideshow {
    margin-bottom: 40px; }
  .slideshow2 .slide {
    min-height: 610px;
    max-height: 610px;
    position: relative; }
    .slideshow2 .slide:after {
      content: " ";
      position: absolute;
      display: block;
      width: 15%;
      height: 100%;
      top: 0;
      left: 85%;
      z-index: -1;
      background: #fff;
      transform-origin: bottom left;
      transform: skew(-10deg, 0deg); }
  .slideshow2 .cycle-prev, .slideshow2 .cycle-next {
    display: inline-block;
    width: 43px;
    height: 43px;
    background: rgba(37, 51, 57, 0.8);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 11px 18px;
    border-radius: 50%;
    cursor: pointer; }
  .slideshow2 .cycle-prev {
    background-image: url("../images/previous-arrow.svg"); }
  .slideshow2 .cycle-next {
    margin-left: 20px;
    background-image: url("../images/next-arrow.svg"); }

@media (max-width: 991px) {
  .slideshow1 .cycle-prev, .slideshow1 .cycle-next {
    width: 68px;
    height: 68px;
    background-size: 17px 27px; }
  .slideshow1 .cycle-prev {
    left: 40px; }
  .slideshow1 .cycle-next {
    right: 40px; } }

@media (max-width: 767px) {
  .slideshow1 .slide {
    min-height: 280px; }
  .slideshow1 .cycle-prev, .slideshow1 .cycle-next {
    width: 34px;
    height: 34px;
    background-size: 9px 14px;
    top: auto;
    bottom: 20px; }
  .slideshow1 .cycle-prev {
    left: 50%;
    transform: translateX(-44px); }
  .slideshow1 .cycle-next {
    right: 50%;
    transform: translateX(44px); }
  .slideshow2 {
    margin-bottom: 30px; }
    .slideshow2 .slideshow {
      margin-bottom: 30px; }
    .slideshow2 .slide {
      min-height: 560px;
      max-height: 560px; }
      .slideshow2 .slide:after {
        width: 100%;
        height: 8%;
        left: 0;
        top: 0;
        transform-origin: top left;
        transform: skew(0deg, -3deg); }
    .slideshow2 .cycle-prev, .slideshow2 .cycle-next {
      width: 34px;
      height: 34px;
      background-size: 8px 14px;
      position: relative; }
    .slideshow2 .cycle-prev {
      left: 50%;
      transform: translateX(-44px); }
    .slideshow2 .cycle-next {
      left: 50%;
      transform: translateX(-44px); } }

#our-services {
  padding: 100px 0px 130px; }
  #our-services h2 {
    margin-bottom: 70px; }
  #our-services li img {
    margin-bottom: 30px;
    width: auto; }
  #our-services .owl-stage-outer {
    margin-bottom: 30px; }

@media (max-width: 767px) {
  #our-services {
    text-align: center;
    padding-bottom: 85px; }
    #our-services h2 {
      margin-bottom: 35px; }
    #our-services li img {
      margin: 0 auto 30px; }
    #our-services .read-more-link {
      display: none; } }

#contact-us {
  padding-bottom: 250px; }
  #contact-us .circle2:before {
    z-index: 1; }
  #contact-us h2 {
    margin-bottom: 30px; }
  #contact-us p {
    margin-bottom: 35px; }

@media (max-width: 767px) {
  #contact-us {
    padding-bottom: 150px; } }

.project-details {
  padding-bottom: 150px; }
  .project-details h1 {
    margin-bottom: 40px; }
  .project-details .project-image {
    margin-right: -140px;
    text-align: right; }
    .project-details .project-image img {
      width: 610px;
      height: 610px;
      border-radius: 50%; }

@media (max-width: 991px) {
  .project-details {
    padding-bottom: 80px; }
    .project-details h1 {
      margin-bottom: 20px; }
    .project-details .project-image {
      margin-right: -175px; }
      .project-details .project-image img {
        width: 540px;
        height: 540px; } }

@media (max-width: 767px) {
  .project-details {
    padding-bottom: 35px; }
    .project-details .project-image {
      margin-right: -100px; }
      .project-details .project-image img {
        width: 380px;
        height: 380px; } }

/*  ==========================================================================
	Pages
    ========================================================================== */
#about-us {
  background: url("../images/about-bg.png") no-repeat center bottom;
  box-shadow: 0 46px 46px 46px rgba(0, 0, 0, 0.2);
  position: relative; }
  #about-us .accent-circle:before {
    z-index: 1; }
  #about-us .arc2:after {
    z-index: 999; }
  #about-us .arc2 .content-column {
    position: relative;
    z-index: 1000; }
  #about-us .box1 {
    min-height: 610px;
    padding-top: 50px;
    padding-bottom: 50px;
    transform: skew(20deg);
    box-shadow: 0px 0px 46px rgba(0, 0, 0, 0.2); }
    #about-us .box1:after {
      content: '';
      display: block;
      width: 150%;
      position: absolute;
      right: -143%;
      top: 0;
      height: 100%;
      background: #fff;
      box-shadow: 40px 0px 46px rgba(0, 0, 0, 0.2); }
    #about-us .box1 blockquote {
      position: relative;
      z-index: 1000;
      transform: skew(-20deg); }
      #about-us .box1 blockquote p.metadata {
        margin: 0; }

@media (max-width: 991px) {
  #about-us .box1 {
    min-height: 430px; }
  #about-us blockquote p.lead {
    font-size: 1.5rem; } }

.home-page .slideshow {
  position: relative; }
  .home-page .slideshow:before {
    content: '';
    display: block;
    background: rgba(37, 51, 57, 0.5);
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000; }

@media (max-width: 767px) {
  #about-us {
    overflow: hidden; }
    #about-us .box1 {
      min-height: 390px;
      position: relative;
      z-index: 9999;
      padding-left: 55px;
      transform: skewY(-4deg);
      margin-bottom: -70px; }
      #about-us .box1 blockquote {
        transform: skewY(4deg); } }

.about-page .p-b {
  padding-bottom: 270px; }

.about-page .circle-group {
  padding-top: 70px;
  margin-bottom: -55px; }
  .about-page .circle-group .container {
    max-width: 1280px; }
  .about-page .circle-group .rounded-circle {
    margin-top: -270px;
    width: 674px;
    height: 674px;
    max-width: none; }
  .about-page .circle-group .circle {
    margin-left: -155px; }

.about-page .box2 {
  position: relative;
  z-index: 9; }
  .about-page .box2 .description {
    box-shadow: inset 0 46px 46px -46px rgba(0, 0, 0, 0.2), inset 0 -46px 46px -46px rgba(0, 0, 0, 0.2); }
    .about-page .box2 .description:after {
      box-shadow: inset 0 46px 46px -46px rgba(0, 0, 0, 0.2), inset 0 -46px 46px -46px rgba(0, 0, 0, 0.2); }

@media (max-width: 1279px) {
  .about-page .p-b {
    padding-bottom: 80px; }
  .about-page .circle-group {
    padding-top: 25px;
    margin-bottom: -110px; }
    .about-page .circle-group .rounded-circle {
      margin-top: -80px;
      width: 423px;
      height: 423px;
      max-width: none; } }

@media (max-width: 991px) {
  .about-page .circle-group .rounded-circle {
    margin-left: -80px; }
  .about-page .circle-group .circle {
    margin-left: -225px; }
    .about-page .circle-group .circle .text-wrap {
      max-width: 330px; } }

@media (max-width: 767px) {
  .about-page .p-b {
    padding-bottom: 0px; }
  .about-page .circle-group {
    padding-top: 10px;
    margin-bottom: -80px; }
    .about-page .circle-group .rounded-circle {
      margin-top: 0px;
      margin-left: -100px;
      width: 380px;
      height: 380px;
      max-width: none; }
    .about-page .circle-group .circle {
      margin-top: -145px;
      margin-left: -70px;
      padding-left: 60px; }
  .about-page .box2 {
    box-shadow: 0 46px 46px -46px rgba(0, 0, 0, 0.2); }
    .about-page .box2 .description {
      box-shadow: none; }
      .about-page .box2 .description:after {
        box-shadow: none; } }

@media (max-width: 480px) {
  .about-page .circle-group .circle .text-wrap {
    max-width: 270px; } }
