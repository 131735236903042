.tabs {
    .active-item {
        display: none;
        position: relative;
        border-bottom: 2px solid $gray-dark;
        padding-bottom: 8px;
        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-left: 3px solid $gray-dark;
            border-bottom: 3px solid $gray-dark;
            transform: rotate(-45deg);
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .nav {
        margin-bottom: 90px;
        justify-content: flex-end;
    }

    .nav-item {
        margin-left: 60px;
        font-size: rem-calc(20);
        &:first-child {
            margin-left: 0px;
        }
    }

    .nav-link {
        padding: 0px 10px 30px;
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 100%;
            border-bottom: 9px solid $complimentary;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: scale(0);
            @include cubic-transition();
        }
        &.active {
            font-weight: 700;
        }
        &.active, &:hover {
            &:after {
                transform: scale(1);
            }
        }
    }
}
@media (max-width:991px) {
    .tabs {
        .nav {
            margin-bottom: 60px;
        }

        .nav-item {
            margin-left: 40px;
        }
    }
}

@media (min-width:768px) {
    .tabs {
        .nav {
            display: flex !important;
        }
    }
}

@media (max-width:767px) {
    .tabs {
        .active-item {
            display: block;
            margin-bottom: 40px;
        }
    
        .nav {
            background: $white;
            border-left: 1px solid $gray-dark;
            border-right: 1px solid $gray-dark;
            width: 100%;
            z-index: 99;
            display: none;
            position: absolute;
            left: 0;
            top: 34px;
        }

        .nav-item {
            margin-left: 0px;
            font-size: rem-calc(16);
        }

        .nav-link {
            padding: 10px 10px;
            &:after {
                border-bottom: 1px solid $gray-dark;
                transform: scale(1);
            }
        }
    }
}