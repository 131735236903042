.project-details {
    padding-bottom: 150px;
    h1 {
        margin-bottom: 40px;
    }
    .project-image {
        margin-right: -140px;
        text-align: right;
        img {
            width: 610px;
            height: 610px;
            border-radius: 50%;
        }
    }
}

@media (max-width:991px) {
    .project-details {
        padding-bottom: 80px;
        h1 {
            margin-bottom: 20px;
        }
        .project-image {
            margin-right: -175px;
            img {
                width: 540px;
                height: 540px;
            }
        }
    }
}

@media (max-width:767px) {
    .project-details {
        padding-bottom: 35px;
        .project-image {
            margin-right: -100px;
            img {
                width: 380px;
                height: 380px;
            }
        }
    }
}