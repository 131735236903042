/* box1 */
.box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    background: $white;
    // box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2), 0 46px 46px -46px rgba(0,0,0,0.2);
    padding-left: 125px;
    padding-right: 15px;
    position: relative;
    // &:before {
    //     display: block;
    //     content: '';
    //     width: 150px;
    //     height: calc(100% + 92px);
    //     background: url('../images/box1-before.jpg') no-repeat;
    //     position: absolute;
    //     left: -86px;
    //     top: -46px;
    // }

    blockquote {
        p {
            &.lead {
                &:before {
                    content: '“';
                    display: block;
                    position: absolute;
                    left: -60px;
                    top: -50px;
                    font-size: rem-calc(100);
                }
            }
        }
    }
}
@media (max-width:767px) {
    .box1 {
        blockquote {
            p {
                &.lead {
                    &:before {
                        left: -40px;
                    }
                }
            }
        }
    }
}