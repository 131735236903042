.menu-btn {
    position: absolute;
    right: 15px;
    z-index: 99;
    padding: 0px;
    background: rgba(0, 0, 0, 0);
    padding: 0px;
    height: 21px;
    width: 25px;
    border: 0px;
    margin-top: 34px;
    cursor: pointer;
    display: none;
    span.icon-bar { 
        background: #292935;
        display: block;
        cursor: pointer;
        height: 3px;
        width: 25px;
        margin-top:5px;
        position: relative;
        transform: rotate(0deg);
        &:first-child {
            margin-top: 0px;
        }
        &:first-child, &:last-child {
            @include cubic-transition();
        }
    }
    &:focus {
        outline: none;
    }
    &.active { 
        span.icon-bar {
            &:first-child + span { background-color: transparent; opacity: 0; }
            &:first-child { transform: rotate(45deg); left: 0px; top: 7px; }
            &:last-child { transform: rotate(-45deg); left: 0px; top: -9px; }
        }
    }
}

@media screen and (max-width:767px) {
    .menu-btn {
        display: block;
    }
}

