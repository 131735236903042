#content {
    padding-top: 70px;
    padding-bottom: 90px;
    background: $white;
}
@media (max-width:767px) {
    #content {
        padding-top: 30px;
        padding-bottom: 60px;
    }
}