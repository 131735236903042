#about-us {
    background: url('../images/about-bg.png') no-repeat center bottom;
    box-shadow: 0 46px 46px 46px rgba(0,0,0,0.2);
    position: relative;
    // &:before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     right: 0;
    //     top: 84px;
    //     width: 50%;
    //     height: 46px;
    //     box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2) inset;
    // }

    // &:after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     right: 0;
    //     bottom: 84px;
    //     width: calc(50% - 64px);
    //     height: 46px;
    //     box-shadow: 0 46px 46px -46px rgba(0,0,0,0.2) inset;
    // }

    .accent-circle:before {
        z-index: 1;
    }

    .arc2 {
        &:after {
            z-index: 999;
        }

        .content-column {
            position: relative;
            z-index: 1000;
        }
    }

    .box1 {
        min-height: 610px;
        padding-top: 50px;
        padding-bottom: 50px;
        transform: skew(20deg);
        box-shadow: 0px 0px 46px rgba(0, 0, 0, 0.2);

        &:after {
            content: '';
            display: block;
            width: 150%;
            position: absolute;
            right: -143%;
            top: 0;
            height: 100%;
            background: #fff;
            box-shadow: 40px 0px 46px rgba(0, 0, 0, 0.2);        
        }

        blockquote {
            position: relative;
            z-index: 1000;
            transform: skew(-20deg);
            p {
                &.metadata {
                    margin: 0;
                }
            }
        }
    }
}
@media (max-width:991px) {
    #about-us {
        .box1 {
            min-height: 430px;
        }

        blockquote {
            p.lead {
                font-size: rem-calc(24);
            }
        }
    }
}

.home-page {
    .slideshow {
            position: relative;
            &:before {
                content: '';
                display: block;
                background: rgba($primary,0.5);
                position: absolute;
                left: 0;
                top: 0px;
                width: 100%;
                height: 100%;
                z-index: 1000;
            }
    }
}

@media (max-width:767px) {
    #about-us {
        overflow: hidden;
        .box1 {
            min-height: 390px;
            position: relative;
            z-index: 9999;
            padding-left: 55px;
            transform: skewY(-4deg);
            margin-bottom: -70px;
            blockquote {
                transform: skewY(4deg);
            }
        }
    }
}