#contact-us {
    padding-bottom: 250px;

    .circle2 {
        &:before {
            z-index: 1;
        }
    }    

    h2 {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 35px;
    }

}

@media (max-width:767px) {
    #contact-us {
        padding-bottom: 150px;
    }
}