#introduction {
    padding-bottom: 100px;
    position: relative;
    // &:before {
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     height: 50%;
    //     background: inherit;
    //     position: absolute;
    //     z-index: 1;
    //     top: -160px;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     transform-origin: top right;
    //     transform: skewY(-3deg);
    //     box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2);
    // }

    .row {
        position: relative;
        z-index: 1;
    }

    p {
        &.lead {
            font-size: rem-calc(50);
            line-height: calc(70 / 50);
        }
    }
}

@media (max-width:1199px) {
    #introduction {
        &:before {
            top: -145px;
        }
    }
}

@media (max-width:991px) {
    #introduction {
        &:before {
            top: -135px;
        }
        p {
            &.lead {
                font-size: rem-calc(35);
            }
        }
    }
}
@media (max-width:767px) {
    #introduction {
        padding-bottom: 40px;
        &:before {
            top: -110px;
        }
        p {
            &.lead {
                font-size: rem-calc(26);
            }
        }
    }
}