.post {
    .display-2 {
        text-transform: uppercase;
        margin-bottom: 55px;
    }

    p {
        strong {
            font-weight: 600;
        }
    }

    blockquote {
        cite {
            font-style: normal;
        }
        
        p {
            &.lead {
            margin-bottom: 35px;
            }

            &.metadata {
                .divider {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }  
    }
}

@media (max-width:991px) {
    .post {
        .display-2 {
            margin-bottom: 40px;
        }
    }
}
