/* read-more-link */
.read-more-link {
    text-transform: uppercase;
    font-weight: 700;
    &:after {
        display: inline-block;
        content: '';
        width: 12px;
        height: 12px;
        border-top: 2px solid $primary;
        border-left: 2px solid $primary;
        transform: rotate(135deg);
        margin-left: 16px;
    }
}

.section {
    padding: 130px 0px;
}
@media (max-width:991px) {
    .section {
        padding: 90px 0px;
    }
}
@media (max-width:767px) {
    .section {
        padding: 60px 0px;
    }
}

.text-wrap {
    position: relative;
    z-index: 2;
}

.display-1 {
    line-height: calc(60 / 55);
}

.lead2 {
    font-size: rem-calc(35);
    line-height: calc(50 / 35);
}
@media (max-width:991px) {
    .display-1 {
        font-size: rem-calc(40);
    }
}
@media (max-width:767px) {
    .display-1 {
        font-size: rem-calc(40);
    }

    &.lead2 {
        font-size: rem-calc(25);
    }
}