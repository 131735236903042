/* footer */
#footer {
    background: $primary;
    background-blend-mode: multiply;
    padding: 20px 0px 106px;
    color: $white;
    position: relative;

    &:before {
        content: '';
        display: block;
        background: rgba($primary,0.5);
        position: absolute;
        left: 0;
        top: -50px;
        width: 100%;
        height: 100%;
        z-index: 9;
        transform: skewY(-3deg);
    }

    &:after {
        background: inherit;
        top: -50px;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-3deg);
    }

    .container {
        position: relative;
        z-index: 99;
    }

    .logo2 {
        padding-top: 25px;
        margin-bottom: 10px;
        position: relative;
        &:before {
            content: '';
            display: block;
            background: url('../images/logo2-bg.png') no-repeat center center;
            position: absolute;
            top: -53px;
            left: -121px;
            width: 294px;
            height: 274px;
        }

        a {
            display: block;
            width: 146px;
            height: 93px;
            background: url('../images/logo2.png') no-repeat right top;
        }
    }

    .copyright {
        font-size: rem-calc(13);
    }

    .contact-details {
        ul {
            padding-left: 0;
            list-style: none;
        }

        li {
            font-size: 1rem;
            line-height: calc(31 / 16);
            img {
                display: inline-block;
                vertical-align: top;
                margin-top: 5px;
                margin-right: 15px;
            }

            a {
                color: inherit;
            }

            .inner {
                display: inline-block;
                // margin-left: 32px;
            }
        }

        address {
            margin-bottom: 0;
            p {
                margin-bottom: 0;
            }
        }
    }

    .note {
        color: rgba($white, 0.53);
        margin-left: 32px;
    }
}

@media (max-width:767px) {
    #footer {
        text-align: center;

        .logo2 {
            width: 146px;
            margin: 0px auto 10px;
        }

        .contact-details {
            width: 230px;
            margin: 0px auto;
            text-align: left;
        }

        .note {
            margin-left: 0px;
        }
    }
}