#header {
    padding-bottom: 40px;
    position: relative;

    // overflow: hidden;
    /*
    &:before {
        content: '';
        display: block;
        width: 120%;
        height: 120px;
        background: inherit;
        position: absolute;
        z-index: 1;
        top: 77%;
        // top: 190px;
        left: -10%;
        // transform-origin: top right;
        transform-origin: center center;
        transform: skewY(-8deg);
        // transform: rotate(-9deg);
        box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2);
    }
    */

    &:before {
        content: '';
        display: block;
        width: 120%;
        height: 120px;
        background: inherit;
        position: absolute;
        z-index: 1;
        top: 199px;
        left: -10%;
        transform-origin: center center;
        transform: skewY(-8deg);
        box-shadow: 0 -46px 46px -46px rgba(0,0,0,0.2);
    }

    .logo {
        padding-top: 30px;
        position: relative;
        &:before {
            width: 438px;
            height: 286px;
            content: '';
            display: block;
            background: url('../images/logo-bg.svg') no-repeat left center;
            position: absolute;
            top: -10px;
            left: -75px;
            z-index: -1;
        }
        a {
            display: block;
            width: 100%;
            max-width: 332px;
            height: 210px;
            background: url('../images/logo.svg') no-repeat right top;
            position: relative;
            left: -48px;
        }
    }
}

@media (max-width:1199px) {
    #header {
        padding-bottom: 30px;
        &:before {
            top: 112px;
            transform: skewY(-6deg);
        }

        .logo {
            padding-top: 25px;
            &:before {
                width: 255px;
                height: 178px;
                left: -12px;
                background-size: contain;
            }
            a {
                max-width: 172px;
                height: 106px;
                left: 22px;
                background-size: contain;
            }
        }
    }
}

@media (max-width:991px) {
    #header {
        &:before {
            top: 122px;
        }
    }
}

@media (max-width:767px) {
    #header {
        padding-bottom: 20px;
        margin-bottom: 30px;
        &:before {
            top: 114px;
        }
        .logo {
            padding-top: 12px;
            &:before {
                width: 210px;
                height: 135px;
                left: -20px;
                top: 0;
            }
            a {
                max-width: 150px;
                height: 96px;
                left: 0;
            }
        }
    }
}