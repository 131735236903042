.owl-carousel {
    overflow: hidden;
    list-style: none;
    padding-left: 0;
    .owl-stage {
        display: flex;
        align-items: flex-start;
    }

    .owl-dots {
        .owl-dot {
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 2px solid $primary;
            background: $primary;
            border-radius: 50%;
            margin-right: 15px;
            &.active {
                background: $white;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

@media (max-width:480px) {
    .owl-carousel {
        .owl-dots {
            .owl-dot {
                margin-right: 10px;
            }
        }
    }
}