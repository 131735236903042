.form {
    .form-group {
        margin-bottom: 50px;
    }

    .form-control {
        font-size: rem-calc(14);
        background: transparent;
        border-width: 0px 0px 2px 0px;
        border-radius: 0px;
        padding-left: 0;
        text-transform: uppercase;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .code {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .code-image {
            width: 185px;
            max-height: 38px;
        }
        .form-control {
            display: inline-block;
            width: calc(100% - 205px);
        }
    }
}

@media (max-width:767px) {
    .form {
        .form-group {
            margin-bottom: 0px;
        }
        .form-control, .code-image {
            margin-bottom: 50px;
        }

        .btn[type="submit"] {
            width: 100%;
        }
    }
}