body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, #container {
  overflow-x: hidden;  
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

h2, h3 {
  text-transform: uppercase;
}

h2 {
  margin-bottom: 45px;
}

p {
  margin-bottom: 30px;
  &.lead {
    line-height: calc(44 / 35);
  }
}

@media (max-width:991px) {
  h1 {
    font-size: rem-calc(40);
  }
}

