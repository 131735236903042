.circle {
    background: $complimentary;
    display: block;
    border-radius: 50%;
    width: 750px;
    height: 750px;
    display: flex;
    align-items: center;
    // z-index: -1;

    .text-wrap {
        position: relative;
        max-width: 390px;
        margin: 0px auto;
        p {
            font-size: rem-calc(20);
        }
    }
}
@media (max-width:767px) {
    .circle {
        width: 700px;
        height: 700px;
        .text-wrap {
            margin: 0px 15px;
        }
    }
}
@media (max-width:480px) {
    .circle {
        .text-wrap {
            max-width: 270px;
        }
    }
}

.has-circle {
    position: relative;
    &:before {
        display: block;
        content: '';
        background: $complimentary;
        border-radius: 50%;
        width: 750px;
        height: 750px;
        position: absolute;
        background-blend-mode: multiply;
    }

    &.accent-circle {
        &:before {
            background-color: $accent;
            width: 173px;
            height: 173px;        
        }
    }
}

.circle1 {
    &:before {
        top: -435px;
        right: -450px;
    }
}

.circle2 {
    &:before {
        top: -10px;
        right: -342px;
    }
}

.circle3 {
    &:before {
        top: -82px;
        right: 0;
        transform: translateX(110%);
    }
}

.services2 {
    .circle3 {
        &:before {
            right: auto;
            top: -246px;
            left: 0px;
            transform: translateX(-88%);
            z-index: 9;
        }
    }
}

.circle4 {
    &:before {
        top: 0px;
        left: 0;
        transform: translateX(-70%);
        z-index: -1;
    }
}

.circle5 {
    &:before {
        top: 0px;
        left: 0;
        transform: translateX(-70%) translateY(27%);
    }
}
.single-project {
    .circle5 {
        &:before {
            transform: translateX(-70%) translateY(-18%);
        }
    }
}

.circle6 {
    &:before {
        width: 918px;
        height: 918px;
        bottom: 0px;
        left: 0;
        transform: translateX(-36%) translateY(0);
        z-index: -1;
    }    
}

.has-arc {
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 500px solid transparent;
        border-right: 500px solid transparent;
        border-top: 670px solid $complimentary;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        background-blend-mode: multiply;
    }
}

.arc1 {
    &:after {
        left: -65%;
        top: -290px;
        border-left-width: 600px;
        border-right-width: 600px;
        border-top-width: 900px;
    }
}

.arc2 {
    &:after {
        left: -263px;
        bottom: -366px;
    }
}

.arc3 {
    &:after {
        transform: translateX(-254px) translateY(-160px);
        left: 0;
        top: 0;
        border-left: 150px solid transparent;
        border-right: 150px solid transparent;
        border-bottom: 180px solid $accent;
        border-top: 0;
    }
}
.slideshow2 {
    &.arc3 {
        &:after {
            left: auto;
            top: auto;
            right: 0;
            bottom: 0;
            transform: translateX(125px) translateY(15px);
            z-index: 100;
        }
    }
}

.arc4 {
    &:after {
        transform: translateX(874px) translateY(-540px);
        right: 0;
        top: 0;
        border-left: 800px solid transparent;
        border-right: 800px solid transparent;
        border-bottom: 950px solid $complimentary;
        border-top: 0;
    }
}

.arc5 {
    &:after {
        transform: translateX(160px) translateY(-330px);
        right: 0;
        top: 0;
        border-left: 280px solid transparent;
        border-right: 280px solid transparent;
        border-bottom: 310px solid $complimentary;
        border-top: 0;
    }
}

.arc6 {
    &:after {
        right: -55%;
        top: 57%;
        border-left: 590px solid transparent;
        border-right: 590px solid transparent;
        border-top: 730px solid $complimentary;
        z-index: -1;
    }
}

@media (max-width:1199px) {
    .circle3 {
        &:before {
            transform: translateX(50%);
        }
    }

    .services2 {
        .circle3 {
            &:before {
                transform: translateX(-45%);
            }
        }
    }

    .arc2 {
        &:after {
            left: -33%;
        }
    }

    .arc3 {
        &:after {
            border-left-width: 114px;
            border-right-width: 114px;
            border-bottom-width: 144px;
            transform: translateX(-170px) translateY(-180px);
        }
    }

    .arc5 {
        &:after {
            transform: translateX(160px) translateY(-220px);
        }
    }
}
@media (max-width:991px) {
    .has-circle {
        &:before {
            width: 590px;
            height: 590px;
        }
    }

    .circle1 {
        &:before {
            right: 0;
            transform: translateX(40%);
        }
    }
    
    .circle6 {
        &:before {
            transform: translateX(-26%) translateY(20%);
        }
    }

    .arc1 {
        &:after {
            left: -87%;
            top: -190px;
        }
    }

    .arc4 {
        &:after {
            transform: translateX(824px) translateY(-415px);
        }
    }

    .arc6 {
        &:after {
            right: -82%;
        }
    }

}
@media (max-width:767px) {
    .has-circle {
        &:before {
            width: 500px;
            height: 500px;
        }
    }

    .circle1 {
        &:before {
            top: -270px;
            transform: translateX(80%);
        }
    }

    .circle3 {
        &:before {
            top: -116px;
        }
    }

    .services2 {
        .circle3 {
            &:before {
                right: 0px;
                top: -146px;
                left: auto;
                transform: translateX(78px);
            }
        }
    }

    .circle5 {
        &:before {
            transform: translateX(-60%) translateY(7%);
        }
    }

    .circle6 {
        &:before {
            transform: translateX(-46%) translateY(20%);
        }
    }

    .arc1 {
        &:after {
            left: auto;
            right: -50%;
            top: -140px;
            // border-left-width: 500px;
            // border-right-width: 500px;
            // border-top-width: 700px;
        }
    }

    .arc2 {
        &:after {
            left: -160%;
            bottom: 10%;
        }
    }

    .arc3 {
        &:after {
            border-left-width: 50px;
            border-right-width: 50px;
            border-bottom-width: 60px;
            transform: translateX(-50px) translateY(-82px);
        }
    }

    .slideshow2 {
        &.arc3 {
            &:after {
                transform: translateX(55px) translateY(15px);
            }
        }
    }

    .arc4 {
        &:after {
            transform: translateX(60%) translateY(15%);
        }
    }

    .arc5 {
        &:after {
            transform: translateX(56%) translateY(-80%);
        }
    }

    .arc6 {
        &:after {
            border-left-width: 380px;
            border-right-width: 380px;
            border-bottom-width: 470px;
            right: -150%;
        }
    }
}